<!--
 * @Date: 2023-10-31 17:25:59
 * @LastEditTime: 2023-12-20 19:40:16
 * @Description: file content
-->
<template>
  <div class="banenr-header-wrap">
    <div class="banner-header" :style="bgImg">
      <div class="banner-header_cont">
        <div class="banner-title" v-html="title"></div>
        <div class="banner-text">
          <div class="banner-item" v-html="desc"></div>
        </div>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import '../../../assets/css/normalize.css';
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    bannerImg: {
      type: String,
      default: '',
    },
  },
  computed: {
    bgImg() {
      return this.bannerImg
        ? { backgroundImage: 'url(' + this.bannerImg + ')' }
        : {};
    },
  },
};
</script>

<style lang="less">
.banner-header {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -80px;
  padding-bottom: 50%;
  box-sizing: border-box;
}
.banner-header_cont {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-186%);
  width: 100%;
}
.banner-title {
  color: #000108;
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
  /* margin: 20px 0 30px; */
}
.banner-text {
  color: #5d5f6c;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  white-space: pre-line;
}
.banner-text .banner-item {
  /* margin-bottom: 20px; */
  text-align: center;
  font-family: PingFang-SC-Medium, PingFang-SC;
}

@media screen and (max-width: 1280px) {
  .banner-title {
    color: #000108;
    font-size: 36px;
    text-align: center;
    margin-bottom: 16px;
    /* margin: 20px 0 30px; */
  }
  .banner-text {
    color: #5d5f6c;
    font-size: 13px;
    line-height: 26px;
    font-weight: 500;
    white-space: pre-line;
  }
}

@media screen and (min-width: 1440px) {
  .banner-header {
    padding-bottom: 820px;
  }

  .banner-header {
    background-position: center;
    background-size: 1440px 820px;
    background-color: #f9f5ff;
  }
}

@media screen and (max-width: 990px) {
  .banner-header_cont {
    position: absolute;
    top: 50%;
    transform: translateY(-130%);
  }
  .banner-title {
    color: #000108;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    /* margin: 20px 0 30px; */
  }
  .banner-text {
    color: #5d5f6c;
    font-size: 9px;
    line-height: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
}
</style>
