<!--
 * @Date: 2023-10-31 18:43:43
 * @LastEditTime: 2023-11-13 18:48:03
 * @Description: file content
-->
<template>
  <div :class="['common-two-column', direction, themeLight]">
    <!--class common-two-column_imgleft -->
    <div class="common-two-column_text">
      <slot name="text">
        <SectionArticle
          class="common-two-column_text-item"
          v-for="(item, index) in list"
          :key="index"
          :title="item.title"
          :desc="item.desc"
        />
      </slot>
    </div>
    <slot>
      <div class="common-two-column_img">
        <slot name="img"><img v-if="img" :src="img" alt="" /></slot>
      </div>
    </slot>
    <slot name="additional"></slot>
  </div>
</template>

<script>
import '../../../assets/css/normalize.css';
import SectionArticle from './SectionArticle.vue';
export default {
  name: 'ColumuTwo',
  components: {
    SectionArticle,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    img: {
      type: String,
      default: '',
    },
    // light or dark
    theme: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeLight() {
      return this.theme === 'light' ? 'common-two-column-light' : '';
    },
    direction() {
      return this.reverse
        ? 'common-two-column_imgleft'
        : 'common-two-column_textleft';
    },
  },
};
</script>

<style>
/* 模块通用两栏布局 */
.common-two-column {
  position: relative;
  /* padding-bottom: 49.5%; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 80px; */
  border-radius: 13px;
  /* overflow: hidden; */
}
.common-two-column_text {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom left;
  /* padding: 80px 40px 0; */
  box-sizing: border-box;
}
.common-two-column_text ul {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 42px;
  justify-content: center;
  padding-bottom: 28px;
  /* padding-left: 20px; */
}
.common-two-column_text li div {
  display: flex;
  align-items: center;
}
.common-two-column_text li div img {
  width: 38px;
  height: auto;
  justify-content: center;
  margin: 0 8px 0 0;
}
.common-two-column_text li p {
  margin-top: 12px;
}
.common-two-column_img {
  /* position: absolute; */
  /* position: relative; */
  top: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
}
.common-two-column_img img {
  width: 100%;
  /* height: 100%; */
}
.common-two-column_imgleft {
  flex-direction: row-reverse;
}
.section-article-desc {
  color: rgba(93, 95, 108, 1);
}
.common-two-column_text-item {
  margin-bottom: 40px;
}
.common-two-column_text-item:last-child {
  margin-bottom: 0;
}

.common-two-column-light .section-article-title {
  color: #fff;
}
.common-two-column-light .section-article-desc {
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 990px) {
  .common-two-column {
    flex-direction: column-reverse;
  }
  .common-two-column_img {
    /* display: none; */
    flex-shrink: 0;
    width: 70% !important;
    margin: 0 auto 10px !important;
  }
  .common-two-column_text {
    width: 100%;
    flex: 0;
    flex-shrink: 0;
  }
}
</style>
