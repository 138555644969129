<!--
 * @Date: 2023-10-31 18:32:04
 * @LastEditTime: 2023-12-04 15:39:30
 * @Description: file content
-->
<template>
  <div :class="['common-title', themeLight]">
    <h3 v-html="title"></h3>
    <div class="common-text">
      <p v-if="desc" v-html="desc"></p>
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
import '../../../assets/css/normalize.css';
export default {
  name: 'CommonTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    // light or dark
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    themeLight() {
      return this.theme === 'light' ? 'common-title-light' : '';
    },
  },
};
</script>

<style scoped>
/* 模块通用头部 */
.common-title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.common-text {
  width: 74%;
}
.common-title p {
  width: 100%;
  color: #000108;
  font-size: 24px;
  margin-bottom: 24px;
  line-height: normal;
}
.common-title p div {
  display: inline-block;
}
.common-title .icon {
  height: 24px;
  vertical-align: top;
}
.common-title .ball {
  /* width: 50px; */
}

.common-title h3 {
  font-size: 40px;
  color: #000108;
}
.common-title-light p,
.common-title-light h3 {
  color: #fff;
}
@media screen and (max-width: 1280px) {
  .common-title p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .common-title h3 {
    font-size: 34px;
  }
}
@media screen and (max-width: 990px) {
  .common-title p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .common-title h3 {
    font-size: 27px;
  }
}
</style>
